
$brand: #018763;
$bright: #039dae; 
$orange: #ffb20e; 
$bg: #f9f9f9;

$text: #333;

$ff: "Open Sans",sans-serif;
$ff-sec: "Raleway",sans-serif;

