@import "system/mixin";
@import "system/variables";
.b-admin_edit {
	padding: 0px 0 20px;
	text-align: center;
	
	}
.post_item_single {

}
.post_item_single img {
	width: 100%;
	max-width: 100%;
}
.post_item_single p {
    font-family: "Open Sans",sans-serif,sans-serif;
    font-size: 16px;
    line-height: 1.8;
    margin: 0 0 15px;
}
.post_item_single a {
font-family: inherit;
font-weight: inherit;
}

.post_item_single p a {
color: $brand;
}

.post_item_single h1 {
    font-family: "Raleway",sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #333; 
    position: relative; 
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.post_item_single h2 {
    font-family: "Raleway",sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #333; 
    position: relative; 
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.post_item_single h3 {
    font-family: "Raleway",sans-serif;
    font-size: 26px;
    font-weight: 700;
    color: #333; 
    position: relative; 
    letter-spacing: 1px;
    margin-bottom: 10px;
}
.post_item_single h4 {
    font-family: "Raleway",sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #333; 
    position: relative;  
    margin-bottom: 10px;
}
.post_item_single h5 {
    font-family: "Raleway",sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #333; 
    position: relative;  
    margin-bottom: 10px;
}

.post_item_single h6 {
    font-family: "Raleway",sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #333; 
    position: relative;  
    margin-bottom: 10px;
}

.post_item_single div {
    margin-bottom: 20px;
}

.post_item_single ul {
	list-style-type: circle;
	padding-left: 20px;
	padding-bottom: 20px;
}
.post_item_single ul li {
	margin-bottom: 0;
	padding-bottom: 7px;
	line-height: 1.8;
	p{
		margin-bottom: 0;
	}
}

.post_item_single ol {
	list-style-type: decimal;
	padding-left: 20px;
	padding-bottom: 20px;
	ol{
		list-style-type: lower-alpha;
	}
}
.post_item_single ol li {
	margin-bottom: 0;
	padding-bottom: 7px;
	line-height: 1.8;
p{
	margin-bottom: 0;
}
}
.post_item_single blockquote {

}

.post_item_single hr {
	
	
}
.post_item_single table {

}

.post_item_single table.table-responsive {
	display: table;
	width: 100%;
    overflow-x: initial;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*
	==============================
		BIG TYPOGRAPHY TEMPLATE
	==============================

<h1>CSS Basic Elements</h1>

<p>The purpose of this HTML is to help determine what default settings are with CSS and to make sure that all possible HTML Elements are included in this HTML so as to not miss any possible Elements when designing a site.</p>

<hr />

<h1>Headings</h1>

<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>


<h1>Paragraph</h1>

<p>Lorem ipsum dolor sit amet, <a href="#" title="test link">test link</a> adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.</p>

<p>Lorem ipsum dolor sit amet, <em>emphasis</em> consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.</p>

<h1>List Types</h1>

<h3>Definition List</h3>
<dl>
	<dt>Definition List Title</dt>
	<dd>This is a definition list division.</dd>
</dl>

<h3>Ordered List</h3>
<ol>
	<li>List Item 1</li>
	<li>List Item 2</li>
	<li>List Item 3</li>
</ol>

<h3>Unordered List</h3>
<ul>
	<li>List Item 1</li>
	<li>List Item 2</li>
	<li>List Item 3</li>
</ul>

<blockquote>
	"This stylesheet is going to help so freaking much." <br />-Blockquote
</blockquote>

<h1>Tables</h1>

<table class="table table-bordered table-responsive table-striped">
	<tr>
		<th>Table Header 1</th><th>Table Header 2</th><th>Table Header 3</th>
	</tr>
	<tr>
		<td>Division 1</td><td>Division 2</td><td>Division 3</td>
	</tr>
	<tr class="even">
		<td>Division 1</td><td>Division 2</td><td>Division 3</td>
	</tr>
	<tr>
		<td>Division 1</td><td>Division 2</td><td>Division 3</td>
	</tr>

</table>

<p>
	Lorem <sup>superscript</sup> dolor <sub>subscript</sub> amet, consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. <cite>cite</cite>. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. <acronym title="National Basketball Association">NBA</acronym> Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.  
<abbr title="Avenue">AVE</abbr>
</p>

<pre>
	<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. <acronym title="National Basketball Association">NBA</acronym> Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.  
	<abbr title="Avenue">AVE</abbr>
	</p>
</pre>


*/